<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="onSelectionChange">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button plain style="float:right" @click="onDelete">设备作废</el-button>
            <!-- <el-button plain style="float:right" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>

    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "设备管理" }],
      debug: 0,
      //弹窗有关
      viewUrlForDialog: ``,
      widthForDialog: "50%",
      isShowDialog: false,
      dataForDialog: null,
      //其他
      btnLoading: false,
      selectionData: [], //勾选的数据
      table: {
        api: vm.$api_hw.equipmentManagement_list,
        query: {
          deviceType: null,
          objectType: null,
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "select",
            title: "设备类型",
            model: "deviceType",
            placeholder: "请选择",
            option: vm.$enums_hw.deviceType.list,
          },
          {
            type: "select",
            title: "应用对象",
            model: "objectType",
            placeholder: "请选择",
            option: vm.$enums_hw.objectType.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "设备号",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "设备号",
            width: "150px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: vm.onDetail.bind(this, ctx.row),
                    },
                  },
                  ctx.row.deviceName || "- -"
                ),
              ]);
            },
          },
          {
            title: "SIM卡号",
            width: "150px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true,
                    },
                    on: {
                      click: ctx.row.simCardNumber
                        ? vm.onDetailSim.bind(this, ctx.row)
                        : () => {},
                    },
                  },
                  ctx.row.simCardNumber || "- -"
                ),
              ]);
            },
          },
          {
            title: "设备类型",
            width: "80px",
            key: "deviceTypeDes",
          },
          {
            title: "应用对象",
            key: "objectTypeDes",
            width: "80px",
          },
          {
            title: "所属标段",
            key: "bidName",
          },
          {
            title: "设备厂家/型号",
            render(h, ctx) {
              return h(
                "span",
                `${ctx.row.deviceCompany ? ctx.row.deviceCompany : " - "} / ${
                  ctx.row.deviceModel ? ctx.row.deviceModel : " - "
                }`
              );
            },
          },
          {
            title: "使用状态",
            key: "useStatusDes",
            width: "80px",
          },
          {
            title: "操作",
            width: "230px",
            render(h, ctx) {
              if (ctx.row.simCardNumber) {
                return h("span", [
                  h(
                    "span",
                    {
                      class: {
                        "table-view-hw": true,
                      },
                      on: {
                        click: vm.onEdit.bind(this, ctx.row),
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "span",
                    {
                      class: {
                        "table-view-line-hw": true,
                      },
                      on: {
                        click: vm.onMatchingCard.bind(this, ctx.row),
                      },
                    },
                    "重新匹配"
                  ),
                  h(
                    "span",
                    {
                      class: {
                        "table-view-line-hw-red": true,
                      },
                      on: {
                        click: vm.onUnbind.bind(this, ctx.row),
                      },
                    },
                    "解绑SIM卡"
                  ),
                ]);
              } else {
                return h("span", [
                  h(
                    "span",
                    {
                      class: {
                        "table-view-hw": true,
                      },
                      on: {
                        click: vm.onEdit.bind(this, ctx.row),
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "span",
                    {
                      class: {
                        "table-view-line-hw": true,
                      },
                      on: {
                        click: vm.onMatchingCard.bind(this, ctx.row),
                      },
                    },
                    "匹配"
                  ),
                ]);
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    onSelectionChange(i) {
      if (i.some((d) => d.useStatus != 0)) {
        Message({
          type: "warning",
          message: "提醒：您选中了一些非 “ 备用 ” 状态的设备，这些设备将无法被作废。",
        });
      }
      this.selectionData = i
        .filter((d) => d.useStatus == 0)
        .map((d) => d.deviceId);
    },
    async onRefresh() {
      await this.$search(this.table);
    },
    onDetailSim(row) {
      this.$router.push({
        path: "/district-management/sim-management/sim-information",
        query: {
          simNumber: row.simCardNumber,
        },
      });
    },
    onEdit(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/equipment-management/edit";
      this.widthForDialog = "75%";
      //传参给弹窗
      this.dataForDialog = {
        deviceId: row.deviceId,
      };
      //显示弹窗
      this.isShowDialog = true;
    },
    /**
     * 设备匹配SIM卡
     */
    onMatchingCard(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/equipment-management/match";
      this.widthForDialog = "50%";
      //传参给弹窗
      this.dataForDialog = {
        deviceId: row.deviceId,
      };
      //显示弹窗
      this.isShowDialog = true;
    },
    onDetail(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/equipment-management/info";
      this.widthForDialog = "75%";
      //传参给弹窗
      this.dataForDialog = {
        deviceId: row.deviceId,
      };
      //显示弹窗
      this.isShowDialog = true;
    },
    onAdd(row) {
      //传views的url
      this.viewUrlForDialog = "/district-management/equipment-management/add";
      this.widthForDialog = "75%";
      //传参给弹窗
      this.dataForDialog = {
        simCardNumber: row.simCardNumber,
      };
      //显示弹窗
      this.isShowDialog = true;
    },
    onUnbind(row) {
      this.$confirm("此操作将解除设备和SIM卡的绑定关系，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api_hw
            .equipmentManagement_unBindSimCardNumber({
              deviceId: row.deviceId,
            })
            .then((res) => {
              this.onRefresh();
              this.$message({
                type: "success",
                message: "解除绑定成功!",
              });
            });
        })
        .catch(() => {});
    },
    onExport() {},
    onDelete() {
      let self = this;
      if (self.selectionData.length === 0) {
        self.$message.warning("请选中至少 1 项 “ 备用 ” 状态的设备");
        return;
      } else {
        self.$msgbox
          .confirm("确定要将选中的项作废吗？", "提示", { type: "warning" })
          .then(async function () {
            self.btnLoading = true;
            try {
              await self.$api_hw.equipmentManagement_obsoleteDevice({
                deviceIds: self.selectionData,
              });
              self.$notify({
                title: "成功",
                message: "操作成功",
                type: "success",
              });
              self.$nextTick(function () {
                self.$search(this.table);
              });
            } catch (e) {
              // console.error(e);
            }
          })
          .catch((e) => {});
        self.btnLoading = false;
      }
    },
  },
  async mounted() {
    let self = this;
    BUS.$on(BUSEVENT.REFRESH_EQUIPMENT_MANAGEMENT, () => {
      self.onRefresh();
    });
  },
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
